<template>
  <div class="container-fluid add-form-list">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header d-flex justify-content-between">
                        <div class="header-title">
                            <h4 class="card-title">출고준비</h4>
                        </div>
                    </div>
                    <div class="card-body">
						<tab-nav :tabs="true" id="myTab-1">
                            <tab-nav-items :active="true" id="box-tab" ariaControls="box" role="tab" :ariaSelected="true" title="출고박스리스트" />
							<tab-nav-items :active="false" id="logis-tab" ariaControls="logis" role="tab" :ariaSelected="false" title="택배접수(쉽먼트)" />
							<tab-nav-items :active="false" id="ship-tab" ariaControls="ship" role="tab" :ariaSelected="false" title="쉽먼트생성(쉽먼트)" />
							<tab-nav-items :active="false" id="load-tab" ariaControls="load" role="tab" :ariaSelected="false" title="적재리스트생성(밀크런/트럭)" />
                            <tab-nav-items :active="false" id="doexport-tab" ariaControls="doexport" role="tab" :ariaSelected="false" title="출고처리" />
						</tab-nav>
                        
                        <tab-content id="myTabContent">
                            <tab-content-item :active="true" id="box" aria-labelled-by="box-tab">
                                <div class="row">
                                    <div class="col-lg-12 mt-2 mb-3">
                                        <p>출고서류 생성 기준(입고예정제품 포함 여부)을 지정하고, 발주센터별 박스수량을 확인해 주세요.<br>
                                        입고예정제품을 포함한 박스예상수량은 상품DB내에 '1박스인입수량'에 기재된 수치를 기준으로 계산되므로 상품DB내 각SKU에 '1박스인입수량'을 먼저 업데이트해주세요.</p>
                                        <!-- <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="customRadio6" name="customRadio-1" class="custom-control-input" v-model="selected2" value="astock">
                                            <label class="custom-control-label" for="customRadio6" v-b-tooltip.top="'국내재고 중 발주배치가 완료된 제품 기준으로 서류접수'">국내발주배치재고기준</label>
                                        </div> -->
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="customRadio7" name="customRadio-1" class="custom-control-input" v-model="selected2" value="default">
                                            <label class="custom-control-label" for="customRadio7" v-b-tooltip.top="'입고완료된 국내 재고 제품 기준으로 서류접수'">국내재고기준</label>
                                        </div>	                                        
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="customRadio8" name="customRadio-1" class="custom-control-input" v-model="selected2" value="includeimport">
                                            <label class="custom-control-label" for="customRadio8" v-b-tooltip.top="'입고예정인 제품을 모두 포함하여 서류접수'">입고예정제품포함</label>
                                        </div>
                                        
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="input-group mb-4">
                                            <div class="input-group-prepend">
                                                <label class="input-group-text" for="inputGroupSelect01">입고예정일</label>
                                            </div>
                                            <b-form-select class="mr-2" v-model="idate" :options="totaldates"></b-form-select>
                                            
                                            <div class="input-group-prepend ml-2">
                                                <label class="input-group-text ml-2" for="inputGroupSelect02">발주센터</label>
                                            </div>
                                            <b-form-select v-model="icenter" :options="totalcenters"></b-form-select>

                                            <div class="input-group-prepend ml-2">
                                                <label class="input-group-text ml-2" for="inputGroupSelect02">발주번호</label>
                                            </div>
                                            <b-form-select v-model="inum" :options="totalnums"></b-form-select>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-2">
                                        <button type="button" class="btn btn-primary" @click="searchexport">검색</button>
                                    </div>
                                    <div class="col-lg-12 mt-4" v-if="showexportlist">
                                        <div class="table-responsive rounded mb-3">
                                            <tableList>
                                                <template v-slot:header>
                                                    <tr class="ligth ligth-data text-center">
                                                        <th>입고예정일</th>
                                                        <th>발주센터</th>
                                                        <th>총박스수량</th>
                                                        <th v-if="selected2 == 'includeimport'">국내박스수량</th>
                                                        <th v-if="selected2 == 'includeimport'">해외박스수량</th>
                                                        <th v-if="selected2 == 'includeimport'">구매박스수량</th>
                                                    </tr>
                                                </template>
                                                <template v-slot:body>
                                                    <tr @click="modaldata(list,index)" class="text-center" v-for="(list,index) in boxlists" :key="index">
                                                        <td>{{list.입고예정일}}</td>
                                                        <td>{{list.발주센터}}</td>
                                                        <td :class="list.총박스수량 > 9 ? 'btn-secondary btn-lg text-white font-weight-bold' : ''">{{list.총박스수량 == 0 ? '' : list.총박스수량.toFixed(2)}}</td>
                                                        <td v-if="selected2 == 'includeimport'">{{list.국내재고박스수량 == 0 ? "" : list.국내재고박스수량.toFixed(2)}}</td>
                                                        <td v-if="selected2 == 'includeimport'">{{list.해외재고박스수량 == 0 ? "" : list.해외재고박스수량.toFixed(2)}}</td>
                                                        <td v-if="selected2 == 'includeimport'">{{list.구매재고박스수량 == 0 ? "" : list.구매재고박스수량.toFixed(2)}}</td>

                                                                <b-modal :id="'modal' + index" scrollable title="박스내역" ok-title="저장" cancel-title="취소" class="detail-modal" @ok="saveboxdetail(index)">
                                                                    <div class="mb-3" v-if="selected2 == 'includeimport'">
                                                                        <span class="mr-3">Priority</span> 
                                                                        <div class="custom-control custom-radio custom-control-inline">
                                                                            <input type="radio" id="customRadio1" name="customRadio-2" class="custom-control-input" v-model="selected3" value="전체" @change="sortbox">
                                                                            <label class="custom-control-label" for="customRadio1" v-b-tooltip.top="'입고예정인 제품을 모두 포함하여 납품'">전체</label>
                                                                        </div>
                                                                        <div class="custom-control custom-radio custom-control-inline">
                                                                            <input type="radio" id="customRadio2" name="customRadio-2" class="custom-control-input" v-model="selected3" value="국내" @change="sortbox">
                                                                            <label class="custom-control-label" for="customRadio2" v-b-tooltip.top="'국내입고완료된 제품만으로 납품'">국내재고우선</label>
                                                                        </div>
                                                                        <div class="custom-control custom-radio custom-control-inline">
                                                                            <input type="radio" id="customRadio3" name="customRadio-2" class="custom-control-input" v-model="selected3" value="해외" @change="sortbox">
                                                                            <label class="custom-control-label" for="customRadio3" v-b-tooltip.top="'국내입고제품과 해외입고완료된 제품까지 납품'">국내재고 & 해외재고우선</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="table-responsive">	
                                                                        <table id="datatable" class="table data-table table-striped dataTable" >
                                                                            <thead>
                                                                                <tr class="ligth text-center" style="font-size:80%;">
                                                                                    <th style="font-size:100%;">발주정보</th>
                                                                                    <th style="font-size:100%;">상품정보</th>
                                                                                    <th style="font-size:100%;">발주확정수량</th>
                                                                                    <th style="font-size:100%;">국내재고수량</th>
                                                                                    <th v-if="selected2 == 'includeimport'" style="font-size:100%;">해외재고수량</th>
                                                                                    <th v-if="selected2 == 'includeimport'" style="font-size:100%;">구매수량</th>
                                                                                    <th style="font-size:100%;">부피대비매출</th>
                                                                                    <th style="font-size:100%;">1박스인입수량</th>
                                                                                    <th style="font-size:100%;">누적박스수량</th>
                                                                                    <th style="font-size:100%;">출고박스번호</th>
                                                                                    
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr class="text-center" v-for="c,i3 in boxdetail" :key="i3" role="row" style="font-size:80%;">
                                                                                    <td style="font-size:100%">{{c.발주번호}}</td>
                                                                                    <td style="max-width:300px;white-space:nowrap;overflow:hidden;">
                                                                                        <div class="d-flex align-items-center">
                                                                                            <img class="avatar-40 rounded" :src="c.등록이미지" alt="#" data-original-title="" title="">
                                                                                            ({{c.SKUID}}){{c.상품명}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>{{c.확정수량 == 0 ? '' : c.확정수량.toFixed(0)}}</td>
                                                                                    <td>{{c.국내재고수량 == 0 ? '' : c.국내재고수량.toFixed(0)}}</td>
                                                                                    <td v-if="selected2 == 'includeimport'">{{c.해외재고수량 == 0 ? '' : c.해외재고수량.toFixed(0)}}</td>
                                                                                    <td v-if="selected2 == 'includeimport'">{{c.구매재고수량 == 0 ? '' : c.구매재고수량.toFixed(0)}}</td>
                                                                                    <td>{{c.부피대비수익률 == 0 ? '' : c.부피대비수익률.toFixed(0)}}</td>
                                                                                    <td>{{c['1박스인입수량']}}</td>
                                                                                    <td>{{c.누적박스수량 == 0 ? '' : c.누적박스수량.toFixed(2)}}</td>
                                                                                    <td>{{c.출고박스번호}}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div> 
                                                                </b-modal>

                                                    </tr>
                                                </template>
                                            </tableList>
                                        </div>
                                        <div class="col-md-12">
                                            <button type="button" class="btn btn-primary mt-4 mr-2" @click="download">1. 택배 접수 양식 다운로드</button>
                                        </div>
                                        <div class="col-md-4 mt-2">
                                            <div class="form-group">
                                                <div class="custom-file">
                                                    <input ref="pdbupload" type="file" @change="readdbFile" class="custom-file-input" id="inputGroupFile02">
                                                    <label class="custom-file-label" for="inputGroupFile02">2. 운송장번호 입력 후 업로드</label>
                                                </div>
                                            </div>
                                        </div> 
                                    </div>


                                   
                                </div>
                            </tab-content-item>
							<tab-content-item :active="false" id="logis" aria-labelled-by="logis-tab">
								<p>다운로드한 파일로 택배 접수 후 운송장번호를 입력하여 다시 업로드해주세요</p>
                                <div class="input-group mb-4">
                                    <div class="input-group-prepend">
                                        <label class="input-group-text" for="inputGroupSelect01">입고예정일</label>
                                    </div>
                                    <b-form-select class="mr-2" v-model="importdate" :options="importdates" @change="changedate"></b-form-select>
                                    
                                    <div class="input-group-prepend ml-2">
                                        <label class="input-group-text ml-2" for="inputGroupSelect02">발주센터</label>
                                    </div>
                                    <b-form-select v-model="발주센터" :options="pocenters" @change="changecenter"></b-form-select>

                                    <div class="input-group-prepend ml-2">
                                        <label class="input-group-text ml-2" for="inputGroupSelect02">발주번호</label>
                                    </div>
                                    <b-form-select v-model="발주번호" :options="ponums" @change="changenum"></b-form-select>
                                </div>
                                <div class="col-lg-12" v-if="lists.length > 0">
                                    <div class="table-responsive rounded mb-3">
                                        <table id="datatable" class="table data-table table-striped dataTable" >
                                            <thead>
                                                <tr class="ligth ligth-data">
                                                    <th :style="columnwidth(m)" v-for="m,i in menus" :key="i">{{m}}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(list,index) in lists" :key="index">
                                                    <td v-for="a,i2 in menus" :key="i2">{{list[a]}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <button type="button" class="btn btn-primary mt-4 mr-2" @click="download">1. 다운로드</button>
                                </div>

                                                            <b-modal ref="bvModal" id="modal" scrollable title="발주배치내역을 확인해 주세요" ok-title="다운로드" cancel-title="취소" class="detail-modal" @ok="downloadexcel">
                                                                
                                                                <span style="font-size:150%;font-weight:bold">확정 대비 배치 제품 수 : </span><span :style="percentagestyle(total.percentage)">{{total.배치수량}}/{{total.확정수량}} ({{total.percentage}}%)</span>
                                                                <div class="table-responsive mt-2">	
                                                                    <table id="datatable" class="table data-table table-striped dataTable" >
                                                                        <thead>
                                                                            <tr class="ligth" style="font-size:80%;">
                                                                                <th style="font-size:100%;">발주번호</th>
                                                                                <th style="font-size:100%;">배치/확정 전체 (%)</th>
                                                                                <th style="font-size:100%;">(SKU ID) 상품명</th>
                                                                                <th style="font-size:100%;">배치/확정 개별 (%)</th>
                                                                                <th style="font-size:100%;">미배치재고</th>
                                                                                <th style="font-size:100%;">구매입고예정</th>
                                                                                <th style="font-size:100%;">회송입고예정</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr v-for="c,i3 in podetail" :key="i3" role="row" style="font-size:80%;">
                                                                                <td style="font-size:120%">{{c.발주번호}}</td>
                                                                                <td :style="percentagestyle(c.percentage)">{{c.배치수량}}/{{c.확정수량}}({{c.percentage}}%)</td>
                                                                                <td style="max-width:300px;white-space:nowrap;overflow:hidden;">
                                                                                    <div class="d-flex align-items-center" v-for="d,i4 in c.발주세부내역" :key="i4">
                                                                                    
                                                                                        <img class="avatar-40 rounded" :src="d.등록이미지" alt="#" data-original-title="" title="">
                                                                                    
                                                                                        ({{d.SKUID}}){{d.상품명}}
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div class="d-flex align-items-center" :style="percentagestyle(d.percentage)" v-for="d,i4 in c.발주세부내역" :key="i4">
                                                                                        {{d.배치수량}}/{{d.확정수량}}({{d.percentage}}%)
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div class="d-flex align-items-center" :style="stockqtystyle(d.미배치재고수량)" v-for="d,i4 in c.발주세부내역" :key="i4">
                                                                                        {{d.미배치재고수량}}
                                                                                    </div>
                                                                                </td>                                                         
                                                                                <td>
                                                                                    <div class="d-flex align-items-center" :style="stockqtystyle(d.구매완료수량)" v-for="d,i4 in c.발주세부내역" :key="i4">
                                                                                        {{d.구매완료수량}}
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div class="d-flex align-items-center" :style="stockqtystyle(d.회송예정수량)" v-for="d,i4 in c.발주세부내역" :key="i4">
                                                                                        {{d.회송예정수량}}
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div> 
                                                            </b-modal>


                                <div class="col-md-4 mt-2">
                                    <div class="form-group">
                                        <div class="custom-file">
                                            <input ref="pdbupload" type="file" @change="readdbFile" class="custom-file-input" id="inputGroupFile02">
                                            <label class="custom-file-label" for="inputGroupFile02">2. 운송장번호 입력 후 업로드</label>
                                        </div>
                                    </div>
                                </div> 
                            </tab-content-item>

                            <tab-content-item :active="false" id="ship" aria-labelled-by="ship-tab">
								<p>택배접수에서 운송장번호가 입력된 박스들만 쉽먼트가 생성되며, 배치수량이 부족한 경우 동일한 발주센터의 송장번호로 자동 입력됩니다.</p>
                                <div class="input-group mb-4">
                                    <div class="input-group-prepend">
                                        <label class="input-group-text" for="inputGroupSelect01">입고예정일</label>
                                    </div>
                                    <b-form-select class="mr-2" v-model="importdate" :options="importdates" @change="changedateship"></b-form-select>
                                    
                                    <div class="input-group-prepend ml-2">
                                        <label class="input-group-text ml-2" for="inputGroupSelect02">발주센터</label>
                                    </div>
                                    <b-form-select v-model="발주센터" :options="pocenters" @change="changecentership"></b-form-select>

                                    <div class="input-group-prepend ml-2">
                                        <label class="input-group-text ml-2" for="inputGroupSelect02">발주번호</label>
                                    </div>
                                    <b-form-select v-model="발주번호" :options="ponums" @change="changenumship"></b-form-select>
                                </div>
								<div class="col-lg-12" v-if="shipmentlists.length > 0">
                                    <div class="table-responsive rounded mb-3">
                                        <table id="datatable" class="table data-table table-striped dataTable" >
                                            <thead>
                                                <tr class="ligth ligth-data">
                                                    <th :style="columnwidth(m)" v-for="m,i in shipmentmenus" :key="i">{{m}}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(shipment,index2) in shipmentlists" :key="index2">
                                                    <td>{{shipment.발주번호}}</td>
                                                    <td>{{shipment.발주센터}}</td>
                                                    <td>{{shipment.입고유형}}</td>
                                                    <td>{{shipment.입고예정일}}</td>
                                                    <td>{{shipment.SKUID}}</td>
                                                    <td>{{shipment.바코드}}</td>
                                                    <td>{{shipment.상품명}}</td>
                                                    <td>{{shipment.확정수량}}</td>
                                                    <td>{{shipment.송장번호}}</td>
                                                    <td>{{shipment.납품수량}}</td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-md-12" v-if="shipmentlists.length > 0">
                                    <button type="button" class="btn btn-primary mt-4 mr-2" @click="shipmentdownload">쉽먼트 파일 생성</button>
                                </div>
                            </tab-content-item>

                            
                            <tab-content-item :active="false" id="load" aria-labelled-by="load-tab">
								<p>적재리스트 생성</p>
								<form @submit.prevent="submit">
									<div class="row">
                                        <div class="col-md-3">
											<div class="form-group">
												<input ref="findbarcode" type="text" class="form-control" v-model="barcodevalue">
											</div>
										</div>
                                        <div class="col-md-3">
											<div class="form-group">
												<input ref="findbarcode" type="text" class="form-control" v-model="barcodevalue">
											</div>
										</div>
                                    </div>
                                </form>
                            </tab-content-item>


                            <tab-content-item :active="false" id="doexport" aria-labelled-by="doexport-tab">
								<p>출고처리가 완료된 발주서의 경우 발주배치가 불가능합니다.</p>

                                <div class="col-lg-12 mt-4">
                                    <card>
                                        <div class="p-3">
                                            <form  @submit.prevent="submit" >
                                                <div class="row">
                                                    <div class="col-md-3 mt-1">
                                                        <div class="form-group">
                                                            <input type="text" class="form-control" placeholder="발주배치박스번호" v-model="배치박스번호">
                                                        </div>
                                                    </div> 
                                                    <div class="col-md-3 mt-1">
                                                        <div class="form-group">
                                                            <input type="text" class="form-control" placeholder="박스위치" v-model="배치파렛트번호">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 mt-1">
                                                        <div class="form-group">
                                                            <input type="text" class="form-control" placeholder="바코드" v-model="바코드">
                                                        </div>
                                                    </div> 
                                                    <div class="col-md-3 mt-1">
                                                        <div class="form-group">
                                                            <input type="text" class="form-control" placeholder="SKU ID" v-model="SKUID">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 mt-1">
                                                        <div class="form-group">
                                                            <input type="text" class="form-control" placeholder="발주번호" v-model="배치발주번호">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 mt-1">
                                                        <div class="form-group">
                                                            <input type="text" class="form-control" placeholder="발주센터" v-model="배치발주센터">
                                                        </div>
                                                    </div>                           
                                                    <div class="col-md-6 mt-1">
                                                        <b-input-group>
                                                            <select class="custom-select col-md-2" style="height:40px" v-model="selectoption">
                                                                <option>입고예정일</option>
                                                            </select>
                                                            <b-form-datepicker id="example-datepicker1" size="sm" v-model="startdate" class="mb-2"></b-form-datepicker>
                                                            <p>~</p>
                                                            <b-form-datepicker id="example-datepicker2" size="sm" v-model="enddate" class="mb-2"></b-form-datepicker>
                                                            
                                                        </b-input-group>
                                                    </div>
                                                </div>                            
                                                <button type="button" class="btn btn-primary mr-2 mt-1" @click="searchdata">검색</button>
                                            </form>
                                        </div>
                                    </card>

                                    <div class="col-lg-12" v-if="showlist">
                                        <div class="table-responsive rounded mb-3">
                                            <tableList>
                                                <template v-slot:header>
                                                    <tr class="ligth ligth-data">
                                                        <th>
                                                            <div class="checkbox d-inline-block">
                                                                <input type="checkbox" class="checkbox-input" id="checkbox1" @change="checkallbox" v-model="checkbox1">
                                                                <label for="checkbox1" class="mb-0"></label>
                                                            </div>
                                                        </th>
                                                        <th>발주번호</th>
                                                        <th>발주센터</th>
                                                        <th>발주운송</th>
                                                        <th>입고예정일</th>
                                                        <th>박스번호</th>
                                                        <th>박스위치</th>
                                                        <th>상품정보</th>
                                                        <th>수량</th>
                                                        <th>운송장번호</th>
                                                    </tr>
                                                </template>
                                                <template v-slot:body>
                                                    <tr v-for="(stocklist,index) in doexportlists" :key="index">
                                                        <td>
                                                            <div class="checkbox d-inline-block">
                                                                <input type="checkbox" class="checkbox-input" id="checkbox2" v-model="stocklist.checked">
                                                                <label for="checkbox2" class="mb-0"></label>
                                                            </div>
                                                        </td>
                                                        <td>{{stocklist.발주번호}}</td>
                                                        <td>{{stocklist.발주센터}}</td>
                                                        <td>{{stocklist.발주운송}}</td>
                                                        <td>{{stocklist.입고예정일}}</td>                                   
                                                        <td>{{stocklist.박스번호}}</td>
                                                        <td>{{stocklist.파렛트번호}}</td>
                                                        <td style="min-width:300px;font-size:80%;">
                                                            <div class="d-flex align-items-center">
                                                                    <img :src="stocklist.등록이미지" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                                                    <div>
                                                                    {{stocklist.상품명}}
                                                                    <p class="mb-0"><small>{{stocklist.바코드}}</small></p>
                                                                    <p class="mb-0"><small>{{stocklist.SKUID}}</small></p>
                                                                    </div>
                                                            </div>
                                                        </td>
                                                        <td>{{stocklist.수량}}</td>
                                                        <td>{{stocklist.운송장번호}}</td>
                                                    </tr>
                                                </template>
                                            </tableList>
                                        </div>
                                    <button type="button" class="btn btn-primary mr-2 mb-4" @click="download">다운로드</button>
                                    <button type="button" class="btn btn-secondary mr-2 mb-4" @click="doexport">출고처리</button>
                                    </div>

                                </div>




                            </tab-content-item>

                            
                        </tab-content>
                    </div>
                </div>
            </div>
        </div>
        <!-- Page end  -->
    </div>
</template>
<script>
import * as XLSX from 'xlsx';
import axios from 'axios';
import { validationMixin } from 'vuelidate';
import { required, email ,sameAs , integer } from 'vuelidate/lib/validators'
export default {
    name:'addExport',
     mixins: [validationMixin],
    validations: {
      name: { required },
      uname: { required },
      email:{ required , email },
      pwd:{required},
      cpwd:{required , sameAsPassword : sameAs('pwd')},
      phonenumber:{required, integer},
    },
    data(){
        return{
            showexportlist: false,

            selected3 : "전체",

            selected2:'default',
            totaldates:['전체'],
            totalcenters:['전체'],
            totalnums:['전체'],

            idate:'전체',
            icenter:'전체',
            inum:'전체',

            boxlists:[],
            boxlistsinit:[],
            boxdetail:[],
            boxdetailinit:[],

            name:'',
            phonenumber: '',
            uname: '',
            email:'',
            pwd:'',
            cpwd:'',
            selectValue:'',
            barcodevalue:'',

            selected: 'box',
            doexportlists: [],
            checkbox1: false,
            selectoption:'입고예정일',

            lists:[],
            shipmentlists:[],
            shipmentlistsinit:[],
            shipmentref:[],
            
            발주센터:'전체',
            pocenters:['전체'],
            발주번호:'전체',
            ponums:['전체'],
            total:{배치수량:0,확정수량:0,percentage:0},

            재고박스번호: '',
            배치박스번호: '',
            배치파렛트번호: '',
            바코드: '',
            SKUID: '',
            배치발주번호: '',
            배치발주센터: '',

            showlist: false,
            doexportlistsinit: [],
            startdate: '',
            enddate: '',


            podetail:[],

            importdates: [],
            importdate: '',
            menus:['수취인명','우편번호','수취인주소','수취인전화번호','수취인핸드폰번호','박스수량','택배운임','운임구분','품목명','배송메세지','박스번호'],
            shipmentmenus:['발주번호(PO ID)','물류센터(FC)','입고유형(Transport Type)','입고예정일(EDD)','상품번호(SKU ID)','상품바코드(SKU Barcode)','상품이름(SKU Name)','확정수량(Confirmed Qty)','송장번호(Invoice Number)','납품수량(Shipped Qty)'],
            option1:[
                  {value: 1, text: 'Male'},
                  {value: 2, text: 'Female'},
                  ],
                  option2:[
                  {value: 1, text: 'Active'},
                  {value: 2, text: 'Inactive'},
                  ],
        }
    },
    
    mounted(){
        // this.menus = this.$store.state.settings.logis;
        this.totaldatesinit()
        this.importdatesinit()
        this.updatelists()  
        
    },
    methods: {
        searchexport(){
            this.showexportlist = false;
            this.changeboxlists()
            setTimeout(() => {
                this.showexportlist = true;
            },30)
        },
        sortbox(){
            var n = 0
            if(this.selected3 == '전체'){
                this.boxdetail = [];
                this.boxdetailinit.forEach(e => {
                    this.boxdetail.push(this.deepClone(e))
                })
                this.boxdetail.sort(function(a,b){
                    if (a.확정수량 < b.확정수량){
                        return 1;
                    }
                    if (a.확정수량 > b.확정수량){
                        return -1;
                    }
                    return 0;
                })
                this.boxdetail.sort(function(a,b){
                    if (a.부피대비수익률 < b.부피대비수익률){
                        return 1;
                    }
                    if (a.부피대비수익률 > b.부피대비수익률){
                        return -1;
                    }
                    return 0;
                })
                this.boxdetail.forEach(e => {
                    n += e.박스수량;
                    e.누적박스수량 = n;
                    e.출고박스번호 = Math.ceil(e.누적박스수량);
                })
            }
            if(this.selected3 == '국내'){
                this.boxdetail.sort(function(a,b){
                    if (a.확정수량 < b.확정수량){
                        return 1;
                    }
                    if (a.확정수량 > b.확정수량){
                        return -1;
                    }
                    return 0;
                })
                this.boxdetail.sort(function(a,b){
                    if (a.부피대비수익률 < b.부피대비수익률){
                        return 1;
                    }
                    if (a.부피대비수익률 > b.부피대비수익률){
                        return -1;
                    }
                    return 0;
                })
                this.boxdetail.sort(function(a,b){
                    if (a.국내재고수량 == 0 && b.국내재고수량 > 0){
                        return 1;
                    }
                    if (a.국내재고수량 > 0 && b.국내재고수량 == 0){
                        return -1;
                    }
                    return 0;
                })                
                this.boxdetail.forEach(e => {
                    n += e.박스수량;
                    e.누적박스수량 = n;
                    e.출고박스번호 = Math.ceil(e.누적박스수량);
                })
            }
            if(this.selected3 == '해외'){
                this.boxdetail.sort(function(a,b){
                    if (a.확정수량 < b.확정수량){
                        return 1;
                    }
                    if (a.확정수량 > b.확정수량){
                        return -1;
                    }
                    return 0;
                })
                this.boxdetail.sort(function(a,b){
                    if (a.부피대비수익률 < b.부피대비수익률){
                        return 1;
                    }
                    if (a.부피대비수익률 > b.부피대비수익률){
                        return -1;
                    }
                    return 0;
                })
                this.boxdetail.sort(function(a,b){
                    if (a.해외재고수량 == 0 && b.해외재고수량 > 0 || a.국내재고수량 == 0 && b.국내재고수량 > 0){
                        return 1;
                    }
                    if (a.해외재고수량 > 0 && b.해외재고수량 == 0 || a.국내재고수량 > 0 && b.국내재고수량 == 0){
                        return -1;
                    }
                    return 0;
                })             
                this.boxdetail.forEach(e => {
                    n += e.박스수량;
                    e.누적박스수량 = n;
                    e.출고박스번호 = Math.ceil(e.누적박스수량);
                })
            }            
        },
        modaldata(list,index){
            var obj = {}
            var arr = [];
            this.boxdetail = [];
            this.boxdetailinit = [];
            console.log(list)
            list.발주내역.forEach(e => {
                e.발주세부내역.forEach(ele => {
                    obj = {
                        발주번호 : e.발주번호,
                        발주센터 : list.발주센터,
                        입고예정일 : list.입고예정일,
                        상품명 : ele.상품명,
                        SKUID : ele.SKUID,
                        바코드 : ele.바코드,
                        등록이미지 : ele.등록이미지,
                        국내재고수량 : ele.국내재고수량,
                        해외재고수량 : ele.해외재고수량,
                        구매재고수량 : ele.구매재고수량,
                        부피대비수익률 : ele.부피대비수익률,
                        '1박스인입수량':ele['1박스인입수량'],
                        박스수량 : ele.국내재고박스수량 + ele.해외재고박스수량 + ele.구매재고박스수량,
                        확정수량 : parseInt(ele.확정수량),
                    }
                    if(obj.부피대비수익률 > 0 && obj.확정수량 > 0){
                        arr.push(obj)
                    }
                })
            })

            arr.sort(function(a,b){
                if (a.부피대비수익률 < b.부피대비수익률){
                    return 1;
                }
                if (a.부피대비수익률 > b.부피대비수익률){
                    return -1;
                }
                return 0;
            })
            
            var copyobj = {};
            var i
            var totalkr
            var totalcn
            var totalpu
            var kr
            var cn
            var pu
            var boxqty
            var totalqty
            arr.forEach(e => {
                if(e.박스수량 > 1){
                    boxqty = e.박스수량;
                    totalqty = e.확정수량;
                    totalkr = e.국내재고수량;
                    totalcn = e.해외재고수량;
                    totalpu = e.구매재고수량;
                    for(i=0;i<e.박스수량+1;i++){ 
                        if(boxqty > 1){
                            if(totalkr >= e['1박스인입수량']){
                                kr = e['1박스인입수량'];
                                cn = 0;
                                pu = 0;
                            } else {
                                if(totalkr + totalcn >= e['1박스인입수량']){
                                    kr = totalkr;
                                    cn = e['1박스인입수량'] - totalkr;
                                    pu = 0;
                                } else {
                                    if(totalkr + totalcn + totalpu >= e['1박스인입수량']){
                                        kr = totalkr;
                                        cn = totalcn;
                                        pu = e['1박스인입수량'] - totalkr - totalcn;
                                    } else {
                                        kr = totalkr;
                                        cn = totalcn;
                                        pu = totalpu;
                                    }
                                }
                            }
                            copyobj = {
                                발주번호 : e.발주번호,
                                발주센터 : e.발주센터,
                                입고예정일 : e.입고예정일,
                                상품명 : e.상품명,
                                SKUID : e.SKUID,
                                바코드 : e.바코드,
                                등록이미지 : e.등록이미지,
                                국내재고수량 : kr,
                                해외재고수량 : cn,
                                구매재고수량 : pu,
                                부피대비수익률 : e.부피대비수익률,
                                '1박스인입수량':e['1박스인입수량'],
                                박스수량 : 1,
                                확정수량 : e['1박스인입수량'],
                            }
                            

                            this.boxdetailinit.push(this.deepClone(copyobj))
                            this.boxdetail.push(this.deepClone(copyobj))
                            boxqty--
                            totalqty -= e['1박스인입수량'];
                            totalkr -= kr;
                            totalcn -= cn;
                            totalpu -= pu;
                            
                        } else {
                            if(totalkr >= e['1박스인입수량']){
                                kr = e['1박스인입수량'];
                                cn = 0;
                                pu = 0;
                            } else {
                                if(totalkr + totalcn >= e['1박스인입수량']){
                                    kr = totalkr;
                                    cn = e['1박스인입수량'] - totalkr;
                                    pu = 0;
                                } else {
                                    if(totalkr + totalcn + totalpu >= e['1박스인입수량']){
                                        kr = totalkr;
                                        cn = totalcn;
                                        pu = e['1박스인입수량'] - totalkr - totalcn;
                                    } else {
                                        kr = totalkr;
                                        cn = totalcn;
                                        pu = totalpu;
                                    }
                                }
                            }
                            copyobj = {
                                발주번호 : e.발주번호,
                                발주센터 : e.발주센터,
                                입고예정일 : e.입고예정일,
                                상품명 : e.상품명,
                                SKUID : e.SKUID,
                                바코드 : e.바코드,
                                등록이미지 : e.등록이미지,
                                국내재고수량 : kr,
                                해외재고수량 : cn,
                                구매재고수량 : pu,
                                부피대비수익률 : e.부피대비수익률,
                                '1박스인입수량':e['1박스인입수량'],
                                박스수량 : boxqty,
                                확정수량 : totalqty,
                            }
                            

                            this.boxdetailinit.push(this.deepClone(copyobj))
                            this.boxdetail.push(this.deepClone(copyobj))
                           
                        }
                    }
                } else {
                    totalkr = e.국내재고수량;
                    totalcn = e.해외재고수량;
                    totalpu = e.구매재고수량;
                    if(totalkr >= e.확정수량){
                        kr = e.확정수량;
                        cn = 0;
                        pu = 0;
                    } else {
                        if(totalkr + totalcn >= e.확정수량){
                            kr = totalkr;
                            cn = e.확정수량 - totalkr;
                            pu = 0;
                        } else {
                            if(totalkr + totalcn + totalpu >= e.확정수량){
                                kr = totalkr;
                                cn = totalcn;
                                pu = e.확정수량 - totalkr - totalcn;
                            } else {
                                kr = totalkr;
                                cn = totalcn;
                                pu = totalpu;
                            }
                        }
                    }
                    copyobj = {
                        발주번호 : e.발주번호,
                        발주센터 : e.발주센터,
                        입고예정일 : e.입고예정일,
                        상품명 : e.상품명,
                        SKUID : e.SKUID,
                        바코드 : e.바코드,
                        등록이미지 : e.등록이미지,
                        국내재고수량 : kr,
                        해외재고수량 : cn,
                        구매재고수량 : pu,
                        부피대비수익률 : e.부피대비수익률,
                        '1박스인입수량':e['1박스인입수량'],
                        박스수량 : e.박스수량,
                        확정수량 : e.확정수량,
                    }
                    this.boxdetailinit.push(this.deepClone(e))
                    this.boxdetail.push(this.deepClone(e))

                    if(e.SKUID == '16213248'){
                        console.log(copyobj)
                    }
                }
            })

            if(this.selected2 == 'default'){
                this.selected3 = '국내'
                this.sortbox()
                setTimeout(() => {
                    this.$bvModal.show( this.modalID(index))
                },50)
            } else {
                this.selected3 = list.selected3;
                this.sortbox()
                setTimeout(() => {
                    this.$bvModal.show(this.modalID(index))
                },50)
            }

        },
        saveboxdetail(index){
            this.boxlists[index].selected3 = this.selected3;
            this.boxdetail.forEach(e => {
                this.boxlists[index].발주내역.forEach(ele => {
                    ele.발주세부내역.forEach(element => {
                        if(ele.발주번호 == e.발주번호 && element.바코드 == e.바코드){
                            element.출고박스번호 = e.출고박스번호
                        }
                    })
                })
            })
        },
        modalID(index) {
            // console.log(index);
            return 'modal' + index
        },
        getTodaywith(){
            var date = new Date();
            var year = date.getFullYear();
            var month = ("0" + (1 + date.getMonth())).slice(-2);
            var day = ("0" + date.getDate()).slice(-2);

            return year + "-" + month + "-" + day;
        }, 
        totaldatesinit(){
            this.boxlistsinit = [];
            var arr = [];
            var obj = {}; // 입고예정일 / 발주센터 / 총박스수량 / 국내재고박스수량 / 물류재고박스수량 / 구매박스수량 / 회송박스수량
         
            //polist중 입고예정일 오늘 이후, 거래명세서포함되지 않음, 발주취소 아닌 것들만 sorting해서 arr로 저장
            this.$store.state.polist.forEach(e => {
                if(!e.발주상태.includes('거래명세서') && !e.발주상태.includes('발주취소') && this.timestamp(this.getTodaywith()) <= this.timestamp(e.입고예정일.slice(0,10))){
                    if(!this.totaldates.includes(e.입고예정일)){
                        this.totaldates.push(e.입고예정일)
                    }
                    if(!this.totalcenters.includes(e.발주센터)){
                        this.totalcenters.push(e.발주센터)
                    }
                    if(!this.totalnums.includes(e.발주번호)){
                        this.totalnums.push(e.발주번호)
                    }
                    obj = {
                        입고예정일 : e.입고예정일,
                        발주센터 : e.발주센터,
                        발주번호 : e.발주번호,
                        발주세부내역 : e.발주세부내역
                    }
                    arr.push(obj)
                }
            })
            var krstock;
            var cnstock;
            var pstock;
            // var rstock;
            var vol;

            var stocks = [];
            
            var so = {}
            var stockfilter = this.$store.state.stock.filter(e => e.stockinfo == 'stock' || this.timestamp(e.배치입고예정일) >= this.timestamp(this.getTodaywith()))
            stockfilter.forEach(e => {
                if(e.site == 'kr'){
                    if(stocks.length > 0 && stocks.filter(ele => ele.바코드 == e.바코드).length > 0){
                        stocks.find(ele => ele.바코드 == e.바코드).수량++
                    } else {
                        so = {
                            site : 'kr',
                            바코드 : e.바코드,
                            수량 : 1
                        }
                        stocks.push(so)
                    }
                } else {
                    if(stocks.length > 0 && stocks.filter(ele => ele.바코드 == e.바코드).length > 0){
                        stocks.find(ele => ele.바코드 == e.바코드).수량++
                    } else {
                        so = {
                            site : 'cn',
                            바코드 : e.바코드,
                            수량 : 1
                        }
                        stocks.push(so)
                    }
                }
            })

            var purchases = [];
            var pobj = {};
            this.$store.state.purchase.filter(e => e.배송상태 != '입고완료').forEach(e => {
                if(purchases.length > 0 && purchases.filter(ele => ele.바코드 == e.바코드).length > 0){
                    purchases.find(ele => ele.바코드 == e.바코드).수량 += e.주문수량
                } else {
                    pobj = {
                        바코드 : e.바코드,
                        수량 : e.주문수량
                    }
                    purchases.push(pobj)
                }
            })

            
            arr.sort(function(a,b){
                if (a.발주번호 < b.발주번호){
                    return -1;
                }
                if (a.발주번호 > b.발주번호){
                    return 1;
                }
                return 0;
            })
            arr.sort(function(a,b){
                if (a.발주센터 < b.발주센터){
                    return -1;
                }
                if (a.발주센터 > b.발주센터){
                    return 1;
                }
                return 0;
            })
            arr.sort(function(a,b){
                return new Date(a.입고예정일) - new Date(b.입고예정일);
            })


            if(!arr.find(e => e.발주세부내역.find(ele => this.$store.state.productdb.find(element => element.바코드 == ele.바코드)['1박스인입수량']))){
                alert('상품별 박스수량 확인을 위해 상품 > 상품DB등록 > 다운로드 후 엑셀에서 "1박스인입수량"열을 먼저 업데이트 해주세요. 1박스인입수량이 업데이트되지 않은 상품은 예상박스수량에 포함되지 않습니다.')
                return true
            }

            console.log(stocks) //가용재고
            console.log(purchases) //구매
            console.log(arr) //발주서내역
            var cnt

            //발주세부내역에 재고수량/재고박스수량 추가
            arr.forEach(e => {
                krstock = 0;
                cnstock = 0;
                pstock = 0;
                // rstock = 0;
                e.발주세부내역.forEach(ele => {
                    cnt = parseInt(ele.확정수량)
                    ele.국내재고수량 = 0;
                    ele.해외재고수량 = 0;
                    ele.구매재고수량 = 0;
                    ele.국내재고박스수량 = 0;
                    ele.해외재고박스수량 = 0;
                    ele.구매재고박스수량 = 0;                  
                    if(this.$store.state.productdb.find(element => element.바코드 == ele.바코드)['1박스인입수량']){
                        vol = 1/this.$store.state.productdb.find(element => element.바코드 == ele.바코드)['1박스인입수량']
                        ele['1박스인입수량'] = this.$store.state.productdb.find(element => element.바코드 == ele.바코드)['1박스인입수량'];
                    } else {
                        vol = 0
                        ele['1박스인입수량'] = 0;
                    }
                    if(stocks.find(element => element.site == 'kr' && element.바코드 == ele.바코드) && cnt > 0){
                        ele.국내재고수량 = stocks.find(element => element.site == 'kr' && element.바코드 == ele.바코드).수량
                        if(cnt >= ele.국내재고수량){
                            ele.국내재고박스수량 = ele.국내재고수량 * vol;
                            krstock += ele.국내재고박스수량;
                            cnt -= ele.국내재고수량;
                        } else {
                            ele.국내재고박스수량 = cnt * vol;
                            krstock += ele.국내재고박스수량;
                            cnt = 0;
                        }
                    }
                    if(stocks.find(element => element.site == 'cn' && element.바코드 == ele.바코드) && cnt > 0){
                        ele.해외재고수량 = stocks.find(element => element.site == 'cn' && element.바코드 == ele.바코드).수량;
                        if(cnt >= ele.해외재고수량){
                            ele.해외재고박스수량 = ele.해외재고수량 * vol;
                            krstock += ele.해외재고박스수량;
                            cnt -= ele.해외재고수량;
                        } else {
                            ele.해외재고박스수량 = cnt * vol;
                            krstock += ele.해외재고박스수량;
                            cnt = 0;
                        }
                    }
                    // rstock += stocks.find(element => element.site == 'kr' && element.바코드 == ele.바코드).수량 * vol;
                    if(purchases.find(element => element.바코드 == ele.바코드) && cnt > 0){
                        ele.구매재고수량 = purchases.find(element => element.바코드 == ele.바코드).수량;
                        if(cnt >= ele.구매재고수량){
                            ele.구매재고박스수량 = ele.구매재고수량 * vol
                            pstock += ele.구매재고박스수량;
                            cnt -= ele.구매재고수량;
                        } else {
                            ele.구매재고박스수량 = cnt * vol;
                            pstock += ele.구매재고박스수량;
                            cnt = 0;
                        }
                    }
                    stocks = stocks.filter(element => element.바코드 != ele.바코드);
                    ele.등록이미지 = this.$store.state.productdb.find(element => element.바코드 == ele.바코드).등록이미지;
                    // console.log(cnt)
                    // console.log(parseInt(ele.매입가.replace(/,/g, '')))
                    // console.log(parseInt(this.$store.state.productdb.find(element => element.바코드 == ele.바코드).매입가))
                    // console.log(ele.국내재고박스수량 + ele.해외재고박스수량 + ele.구매재고박스수량)
                    if(ele.국내재고수량 + ele.해외재고수량 + ele.구매재고수량 == 0){
                        ele.부피대비수익률 = 0
                    } else {
                        ele.부피대비수익률 = (parseInt(ele.매입가.replace(/,/g, ''))) / vol
                    }
                    ele.부족재고 = cnt;
                    
                })
                e.국내재고박스수량 = krstock;
                e.해외재고박스수량 = cnstock;
                e.구매재고박스수량 = pstock;
                e.총박스수량 = krstock + cnstock + pstock;
                // e.회송재고박스수량 = rstock;
                
            })


            //발주내역에서 출고박스리스트 추출
            var oj = {}
            var ojadd = {}
            var ta
            arr.forEach(e => {
                if(this.boxlistsinit.length > 0 && this.boxlistsinit.filter(ele => ele.발주센터 == e.발주센터 && ele.입고예정일 == e.입고예정일).length > 0){
                    
                    ojadd = {
                        발주번호 : e.발주번호,
                        발주세부내역 : e.발주세부내역
                    }
                    ta = this.boxlistsinit.find(ele => ele.발주센터 == e.발주센터 && ele.입고예정일 == e.입고예정일)
                    ta.발주내역.push(ojadd)
                    ta.국내재고박스수량 += e.국내재고박스수량;
                    ta.해외재고박스수량 += e.해외재고박스수량;
                    ta.구매재고박스수량 += e.구매재고박스수량;
                    ta.총박스수량 = ta.국내재고박스수량 + ta.해외재고박스수량 + ta.구매재고박스수량
                } else {
                    oj = {
                        국내재고박스수량 : e.국내재고박스수량,
                        해외재고박스수량 : e.해외재고박스수량,
                        구매재고박스수량 : e.구매재고박스수량,
                        발주내역 : [{
                            발주번호 : e.발주번호,
                            발주세부내역 : e.발주세부내역
                        }],
                        총박스수량 : e.총박스수량,
                        발주센터 : e.발주센터,
                        입고예정일 : e.입고예정일,
                        selected3 : '전체'
                    }
                    if(e.총박스수량 > 0){
                        this.boxlistsinit.push(oj)
                    }
                }
            })


            this.changeboxlists()
        },
        showbox(){
            if(this.selected2 == 'default'){
                this.boxlists = [];
                setTimeout(() => {
                    this.boxlistsinit.forEach(e => {
                        this.boxlists.push(this.deepClone(e))
                    })
                    this.boxlists.forEach(e => {
                        e.총박스수량 = e.국내재고박스수량;
                    })
                    this.boxlists = this.boxlists.filter(e => e.총박스수량 > 0) 
                }, 10);
            } else {
                this.boxlists = [];
                setTimeout(() => {
                    this.boxlistsinit.forEach(e => {
                        this.boxlists.push(this.deepClone(e))
                    })
                    this.boxlists = this.boxlists.filter(e => e.총박스수량 > 0)
                }, 10);
            }
        },
        changeboxlists(){
            this.showbox();
            setTimeout(() => {
                if(this.idate != '전체'){
                    this.boxlists = this.boxlists.filter(e => e.입고예정일 == this.idate)
                }
                if(this.icenter != '전체'){
                    this.boxlists = this.boxlists.filter(e => e.발주센터 == this.icenter)
                }
                if(this.inum != '전체'){
                    var arr = []
                    var obj = {}
                    var ob = {}
                    this.boxlists.filter(e => e.발주내역.filter(ele => ele.발주번호 == this.inum).length > 0).some(e => {
                        if(e.발주내역.find(ele => ele.발주번호 == this.inum)){
                            ob = e.발주내역.find(ele => ele.발주번호 == this.inum)
                            obj = {
                                국내재고박스수량 : ob.발주세부내역.국내재고박스수량,
                                해외재고박스수량 : ob.발주세부내역.해외재고박스수량,
                                구매재고박스수량 : ob.발주세부내역.구매재고박스수량,
                                발주내역 : [{
                                    발주번호 : ob.발주번호,
                                    발주세부내역 : ob.발주세부내역
                                }],
                                총박스수량 : this.selected2 == 'default' ? ob.발주세부내역.국내재고박스수량 : ob.발주세부내역.국내재고박스수량+ob.발주세부내역.해외재고박스수량+ob.발주세부내역.구매재고박스수량,
                                발주센터 : e.발주센터,
                                입고예정일 : e.입고예정일,
                                selected3 : '전체'
                            }
                            arr.push(obj)
                            return true
                        }
                    })
                    this.boxlists = arr
                }
            },10)
        },
        doexport(){
            if(this.doexportlists.filter(e => e.checked).length > 0){
                if(this.doexportlists.filter(e => e.수량 > 0 && e.발주운송 == '쉽먼트' && !e.운송장번호).length > 0){
                    alert('배치된 제품은 택배접수>쉽먼트생성 후 출고 처리가 가능합니다.')
                } else {
                    axios.post('/api/addexport/addlist',this.doexportlists.filter(e => e.checked))
                    .then((res) => {
                        console.log(res)
                        this.$store.commit('exportlistupdate',res.data.exportlist);
                        var arr = [];
                        this.$store.state.stock.forEach(e => {
                            res.data.exportlist.forEach(ele => {
                                if(e.배치발주번호 == ele.발주번호 && e.바코드 == ele.바코드){
                                    arr.push(e)
                                }
                            })
                        })
                        //서버에서도 stock 삭제
                        // this.$store.commit('stockdelete',arr);
                        this.searchdata();
                        alert(res.data.요청결과)
                    })
                    .catch(console.log);
                }
            } else {
                alert('출고처리할 내역을 선택해 주세요')
            }
        },
        async searchdata(){
            this.showlist = false;
            var res = await this.search();
            setTimeout(async () => {
                this.showlist = await res.showliststatus;    
            }, 50);
            // console.log(this.doexportlists)            
            
        },
        async search(){
            // console.log(this.$store.state.exportlist)
            this.showlist = false;
            this.doexportlists = [];
            this.doexportlistsinit = [];
            var arr = [];
            var obj = {};
            if(this.$store.state.stock.length > 0){
                arr = this.$store.state.stock.filter(e => e.stockinfo == "Astock");

                
                
                this.$store.state.polist.filter(ele => !ele.발주상태.includes("거래명세서") && !ele.발주상태.includes("발주취소")).forEach(ele => {
                    ele.발주세부내역.forEach(element => {                    
                        if(arr.filter(e => e.배치발주번호 == ele.발주번호).length == 0){
                            obj = {
                                _id:'',
                                companyid:this.$store.state.user.companyid,
                                바코드:element.바코드,
                                상품명:element.상품명,
                                SKUID:element.SKUID,
                                구매id:"",
                                회송id:"",
                                입고일시:"",
                                입고송장번호:"",
                                재고박스번호:"",
                                재고파렛트번호:"",
                                배치일시:"",
                                배치발주번호:ele.발주번호,
                                배치박스번호:"",
                                배치파렛트번호:"",
                                배치발주센터:ele.발주센터,
                                배치입고예정일:ele.입고예정일,
                                운송장번호:'',
                                stockinfo:"Astock",
                            }
                            arr.push(obj)
                        }
                    })
                })


                arr.sort(function(a,b){
                    return new Date(b.배치입고예정일) - new Date(a.배치입고예정일);
                })
                arr.sort(function(a,b){
                    if (a.발주번호 < b.발주번호){
                        return -1;
                    }
                    if (a.발주번호 > b.발주번호){
                        return 1;
                    }
                    return 0;
                })
                arr.sort(function(a,b){
                    if (a.발주센터 < b.발주센터){
                        return -1;
                    }
                    if (a.발주센터 > b.발주센터){
                        return 1;
                    }
                    return 0;
                })

                arr.forEach(e => {
                    if(!e.운송장번호){
                        e.운송장번호 = ""
                    }
                    if(this.$store.state.exportlist.length > 0 && this.$store.state.exportlist.filter(ele => ele.발주번호 == e.배치발주번호 && ele.바코드 == e.바코드).length > 0){
                        return
                    } else {
                        if(e.배치일시){
                            if(this.doexportlistsinit.filter(ele => ele.발주번호 == e.배치발주번호 && ele.바코드 == e.바코드).length == 0){
                                obj = {
                                    companyid : this.$store.state.user.companyid,
                                    checked: false,
                                    발주번호 : e.배치발주번호,
                                    발주센터 : e.배치발주센터,
                                    발주운송 : this.$store.state.polist.find(ele => ele.발주번호 == e.배치발주번호).발주운송,
                                    입고예정일 : e.배치입고예정일,
                                    박스번호 : e.배치박스번호,
                                    파렛트번호 : e.배치파렛트번호,
                                    등록이미지 : this.$store.state.productdb.find(ele => ele.바코드 == e.바코드).등록이미지,
                                    상품명 : e.상품명,
                                    바코드 : e.바코드,
                                    SKUID : e.SKUID,
                                    수량 : 1,
                                    운송장번호 : e.운송장번호
                                }
                                this.doexportlistsinit.push(obj)
                            } else {
                                this.doexportlistsinit.find(ele => ele.발주번호 == e.배치발주번호 && ele.바코드 == e.바코드).수량++
                            }
                        } else {
                            if(this.doexportlistsinit.filter(ele => ele.발주번호 == e.배치발주번호 && ele.바코드 == e.바코드).length == 0){
                                obj = {
                                    companyid : this.$store.state.user.companyid,
                                    checked: false,
                                    발주번호 : e.배치발주번호,
                                    발주센터 : e.배치발주센터,
                                    발주운송 : this.$store.state.polist.find(ele => ele.발주번호 == e.배치발주번호).발주운송,
                                    입고예정일 : e.배치입고예정일,
                                    박스번호 : e.배치박스번호,
                                    파렛트번호 : e.배치파렛트번호,
                                    등록이미지 : this.$store.state.productdb.find(ele => ele.바코드 == e.바코드).등록이미지,
                                    상품명 : e.상품명,
                                    바코드 : e.바코드,
                                    SKUID : e.SKUID,
                                    수량 : 0,
                                    운송장번호 : e.운송장번호
                                }
                                this.doexportlistsinit.push(obj)
                            }                        
                        }
                    }
                })

                this.doexportlists = this.doexportlistsinit;
                if(this.배치박스번호.length > 0){
                    this.doexportlists = this.doexportlistsinit.filter(e => e.박스번호 == this.배치박스번호)
                }
                if(this.배치파렛트번호.length > 0){
                    this.doexportlists = this.doexportlistsinit.filter(e => e.파렛트번호 == this.배치파렛트번호)
                }
                if(this.바코드.length > 0){
                    this.doexportlists = this.doexportlistsinit.filter(e => e.바코드 == this.바코드)
                }
                if(this.SKUID.length > 0){
                    this.doexportlists = this.doexportlistsinit.filter(e => e.SKUID == this.SKUID)
                }
                if(this.배치발주번호.length > 0){
                    this.doexportlists = this.doexportlistsinit.filter(e => e.발주번호 == this.배치발주번호)
                }
                if(this.배치발주센터.length > 0){
                    this.doexportlists = this.doexportlistsinit.filter(e => e.발주센터 == this.배치발주센터)
                }                              
                if(this.startdate || this.enddate){
                    this.doexportlists = this.doexportlistsinit.filter(e => this.timestamp(e.입고예정일) >= this.timestamp(this.startdate) && this.timestamp(e.입고예정일) <= this.timestamp(this.enddate))
                }                
                
                return {showliststatus:true}
            } else {
                alert("재고리스트가 없습니다.")
                return {showliststatus:false}
            }
        },
        timestamp(date){
            //inputdate = "yyyy-mm-dd"
            return new Date(date).getTime() / 1000
        },
        changestockinfo(stockinfo){
            if(stockinfo == "stock") {
                return "재고"
            }
            if(stockinfo == "Astock") {
                return "배치재고"
            }
            if(stockinfo == "Pstock") {
                return "구매재고"
            }
            if(stockinfo == "Rstock") {
                return "회송재고"
            }                      
        },
        changeboxinfo(stockinfo,재고박스번호,배치박스번호){
            if(stockinfo == "stock") {
                return 재고박스번호
            }
            if(stockinfo == "Astock") {
                return 배치박스번호
            }
            if(stockinfo == "Pstock") {
                return "구매입고전"
            }
            if(stockinfo == "Rstock") {
                return "회송입고전"
            } 
        },
        changepalletinfo(stockinfo,재고파렛트번호,배치파렛트번호) {
            if(stockinfo == "stock") {
                return 재고파렛트번호
            }
            if(stockinfo == "Astock") {
                return 배치파렛트번호
            }
            if(stockinfo == "Pstock") {
                return "구매입고전"
            }
            if(stockinfo == "Rstock") {
                return "회송입고전"
            }            
        },
        badgecolor(stockinfo){
            if(stockinfo == "stock") {
                return "badge badge-primary"
            }
            if(stockinfo == "Astock") {
                return "badge badge-success"
            }
            if(stockinfo == "Pstock") {
                return "badge badge-secondary"
            }
            if(stockinfo == "Rstock") {
                return "badge badge-danger"
            } 
        },
        checkallbox(){
            if(this.checkbox1){
                this.doexportlists.forEach(e => e.checked = true)
            } else {
                this.doexportlists.forEach(e => e.checked = false)
            }
        },


        percentagestyle(percentage){
            if(percentage < 100){
                return "color:red;font-weight:bold;font-size:150%;height:40px;"
            } else {
                return "font-size:150%;height:40px;"
            }
        },
        stockqtystyle(qty){
            if(qty > 0){
                return "color:red;font-weight:bold;font-size:150%;height:40px;"
            } else {
                return "font-size:150%;height:40px;"
            }
        },                     
        readdbFile(event) {

            this.pdbuploadstatus = "업로드 중 ...... (업로드가 완료될 때까지 기다려 주세요)";
            const file = event.target.files[0];
            // console.log(file)
            if(file){
                let reader = new FileReader();
            
                reader.onload = (e) => {
                    // let data = reader.result;
                    let data = e.target.result;
                    let workbook = XLSX.read(data, {type: 'binary'});
                    workbook.SheetNames.forEach(sheetName => {
                        const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
                        console.log(roa)
                        console.log(roa[0].박스번호.split('_')[3])

                        var obj = {};
                        var obj2 = {};

                        var ar = [];
                        var arr = [];
                        roa.forEach(e => {
                            this.$store.state.stock.forEach(ele => {
                                if(ele.배치박스번호 == e.박스번호.split('_')[3] && ele.배치입고예정일.slice(5,7) + ele.배치입고예정일.slice(8,10) == e.박스번호.split('_')[1] && ele.배치발주센터 == e.박스번호.split('_')[0]){
                                    if(ar.length == 0 || ar.filter(element => element.발주번호 == ele.배치발주번호 && element.바코드 == ele.바코드).length == 0){
                                        obj = {
                                            발주번호:ele.배치발주번호,
                                            발주센터:ele.배치발주센터,
                                            입고유형:this.$store.state.polist.find(element => element.발주번호 == ele.배치발주번호).발주운송,
                                            입고예정일:ele.배치입고예정일,
                                            SKUID:ele.SKUID,
                                            바코드:ele.바코드,
                                            상품명:ele.상품명,
                                            확정수량:this.$store.state.polist.find(element => element.발주번호 == ele.배치발주번호).발주세부내역.find(element => element.바코드 == ele.바코드).확정수량,
                                            송장번호:e.운송장번호,
                                            납품수량:1
                                        }
                                        obj2 = {
                                            발주번호:ele.배치발주번호,
                                            발주센터:ele.배치발주센터,
                                            입고유형:this.$store.state.polist.find(element => element.발주번호 == ele.배치발주번호).발주운송,
                                            입고예정일:ele.배치입고예정일,
                                            SKUID:ele.SKUID,
                                            바코드:ele.바코드,
                                            상품명:ele.상품명,
                                            확정수량:this.$store.state.polist.find(element => element.발주번호 == ele.배치발주번호).발주세부내역.find(element => element.바코드 == ele.바코드).확정수량,
                                            송장번호:e.운송장번호,
                                            납품수량:obj.확정수량-1
                                        }
                                        ar.push(obj)
                                        arr.push(obj2)
                                        
                                    } else {
                                        ar.find(element => element.발주번호 == ele.배치발주번호 && element.바코드 == ele.바코드).납품수량++
                                        arr.find(element => element.발주번호 == ele.배치발주번호 && element.바코드 == ele.바코드).납품수량--
                                    }
                                }
                            })
                        })

                       
                        arr.forEach(e => {
                            if(e.납품수량 != 0){
                                ar.find(ele => ele.발주번호 == e.발주번호 && ele.바코드 == e.바코드).납품수량 += e.납품수량;
                            }
                        })

                        ar.forEach(e => {
                            if(this.shipmentlistsinit.length == 0){
                                this.shipmentlistsinit.push(e)
                            } else {
                                if(this.shipmentlistsinit.filter(ele => ele.발주번호 == e.발주번호 && ele.바코드 == e.바코드).length == 0){
                                    this.shipmentlistsinit.push(e)
                                }
                            } 
                        })

                        this.updateshipmentlists();
                        this.uploadshipmentlists();

                        // console.log(this.$store.state.user.companyid)
                        // roa.forEach(e => {delete e._id});
                        // if (roa[0].companyid == this.$store.state.user.companyid) {
                        //     
                        
                        // } else {
                        //     alert("Company ID가 일치하지 않습니다")
                        // }
                    });
                };
                reader.readAsBinaryString(file);
            }
        },
        uploadshipmentlists(){
            console.log(this.shipmentlistsinit)
            // this.shipmentlistsinit.forEach(e => {
            //     송장번호 서버로 전송
            // })
        },
        deepClone(obj) {
			if (obj === null || typeof obj !== "object") {
				return obj
			}

			const result = Array.isArray(obj) ? [] : {}

			for (let key of Object.keys(obj)) {
				result[key] = this.deepClone(obj[key])
			}

			return result
		},
        columnwidth(m){
            if(m == "수취인주소" || m == '상품이름(SKU Name)'){
                return "min-width:300px;"
            }
        },
        download() {
            this.updatelists()
            this.$bvModal.show('modal')
        },
        shipmentdownload(){
			function getToday(){
				var date = new Date();
				var year = date.getFullYear();
				var month = ("0" + (1 + date.getMonth())).slice(-2);
				var day = ("0" + date.getDate()).slice(-2);

				return year + month + day;
			}

            var arr = [];
            var blankarr = [];
            var obj = {};
            this.shipmentlists.forEach(e => {
                obj = {
                    '발주번호(PO ID)' : e.발주번호,
                    '물류센터(FC)' : e.발주센터,
                    '입고유형(Transport Type)' : e.입고유형,
                    '입고예정일(EDD)' : e.입고예정일,
                    '상품번호(SKU ID)' : e.SKUID,
                    '상품바코드(SKU Barcode)' : e.바코드,
                    '상품이름(SKU Name)' : e.상품명,
                    '확정수량(Confirmed Qty)' : e.확정수량,
                    '송장번호(Invoice Number)' : e.송장번호,
                    '납품수량(Shipped Qty)' : e.납품수량
                }
                arr.push(obj);
            })

            const myHeader = ["발주번호(PO ID)","물류센터(FC)","입고유형(Transport Type)","입고예정일(EDD)","상품번호(SKU ID)","상품바코드(SKU Barcode)","상품이름(SKU Name)","확정수량(Confirmed Qty)","송장번호(Invoice Number)","납품수량(Shipped Qty)"];
			const workBook = XLSX.utils.book_new()
			const workSheet1 = XLSX.utils.json_to_sheet(arr, {header : myHeader})
            const workSheet2 = XLSX.utils.json_to_sheet(blankarr)
			XLSX.utils.book_append_sheet(workBook, workSheet1, '상품목록')
            XLSX.utils.book_append_sheet(workBook, workSheet2, '송장번호입력')
			XLSX.writeFile(workBook, '쉽먼트파일' + '_' + getToday() + '.xlsx')
          
        },
        downloadexcel(){
            console.log(this.podetail)
			function getToday(){
				var date = new Date();
				var year = date.getFullYear();
				var month = ("0" + (1 + date.getMonth())).slice(-2);
				var day = ("0" + date.getDate()).slice(-2);

				return year + month + day;
			}
            var arr = this.lists
            arr.forEach(e => {
                e.운송장번호 = ''
            })

			const workBook = XLSX.utils.book_new()
			const workSheet1 = XLSX.utils.json_to_sheet(arr)
			XLSX.utils.book_append_sheet(workBook, workSheet1, '택배접수')
			XLSX.writeFile(workBook, '택배접수' + '_' + getToday() + '.xlsx')
            this.$bvModal.show('modal')

             setTimeout(() => {
				this.$nextTick(() => {
					this.$bvModal.hide('modal')
				})				
			}, 10);
		},
        changedate(){
            this.발주센터 = '전체';
            this.발주번호 = '전체';
            this.updatelists();
        },
        changecenter(){
            this.updatelists();
        },        
        changenum(){
            this.updatelists();
        },    
        changedateship(){
            this.발주센터 = '전체';
            this.발주번호 = '전체';
            this.updateshipmentlists();
        },
        changecentership(){
            this.updateshipmentlists();
        },        
        changenumship(){
            this.updateshipmentlists();
        },
        updateshipmentlists(){
            this.ponums = ['전체'];
            this.pocenters = ['전체'];
            this.shipmentlists = this.shipmentlistsinit.filter(e => e.입고예정일 == this.importdate);
            if(this.발주센터 != '전체'){
                 this.shipmentlists = this.shipmentlists.filter(e => e.발주센터 == this.발주센터)
            } else {
                if(this.발주번호 != '전체'){
                    this.shipmentlists = this.shipmentlists.filter(e => e.발주번호 == this.발주번호)
                }
            }
            this.shipmentlists.forEach(e => {
                this.pocenters.push(e.발주센터);
                this.ponums.push(e.발주번호);
            })
        },
        updatelists(){
            var zipcode = {
                광주:12814,
                대구2:39868,
                대구6:39868,
                동탄1:18465,
                마장1:17390,
                부천1:14446,
                서울:5842,
                안산2:15657,
                안성5:17555,
                양산1:50615,
                용인1:17180,
                이천3:17401,
                인천4:22849,
                창원4:51599,
                천안:31025,
                평택1:17962,
                고양1:10550,
                안성4:17521,
                인천1:22849,
                호법:17396,
                광주4:62466,
                목천1:31251,
                창원1:51599,
                인천5:22849,
                시흥2:15074,
                김해2:50804,
                이천4:17405,
                이천2:17383,
                인천18:22362,
                안성8:17517,
                대구3:43008,
            }
            var arr = [];
            var obj = {};
            this.ponums = ['전체'];
            this.pocenters = ['전체'];
            var stocks = this.$store.state.stock.filter(e => e.stockinfo == 'stock' || this.timestamp(e.배치입고예정일) >= this.timestamp(this.getTodaywith()))
            if(this.입고예정일 != '전체'){
                stocks = stocks.filter(e => e.stockinfo == "Astock" && e.배치입고예정일 == this.importdate);
            }
            if(this.발주센터 != '전체'){
                stocks = stocks.filter(e => e.배치발주센터 == this.발주센터)
            }
            if(this.발주번호 != '전체'){
                stocks = stocks.filter(e => e.배치발주번호 == this.발주번호)
            }
            console.log(stocks)
            var zip = '';
            stocks.forEach(e => {
                if(arr.length == 0 || arr.filter(ele => ele.품목명 == '잡화 (' + e.배치발주센터 + '_' + e.배치입고예정일.slice(5,7)+ e.배치입고예정일.slice(8,10) + '_' + e.배치파렛트번호 + '_' + e.배치박스번호 + ')' && ele.수취인명 == e.배치발주센터).length == 0){
                    var address = this.$store.state.polist.filter(element => element.발주센터 == e.배치발주센터 && element.입고주소)[0].입고주소
                    // console.log(this.$store.state.polist.filter(element => element.발주센터 == e.배치발주센터 && element.입고주소))
                    // console.log(e.배치발주센터)
                    // console.log(address)
                    zip = '';
                    if(zipcode[e.배치발주센터]){
                       zip = zipcode[e.배치발주센터];
                    }
                    obj = {
                        수취인명: e.배치발주센터,
                        우편번호: zip,
                        수취인주소: address,
                        수취인전화번호: address.slice(address.search(": ")+2,address.length-1),
                        수취인핸드폰번호: address.slice(address.search(": ")+2,address.length-1),
                        박스수량: 1,
                        택배운임: '',
                        운임구분: '선불',
                        품목명: '잡화 (' + e.배치발주센터 + '_' + e.배치입고예정일.slice(5,7)+ e.배치입고예정일.slice(8,10) + '_' + e.배치파렛트번호 + '_' + e.배치박스번호 + ')',
                        blank1: '',
                        배송메세지: '',
                        blank2: '',
                        blank3: '',
                        박스번호: e.배치발주센터 + '_' + e.배치입고예정일.slice(5,7)+ e.배치입고예정일.slice(8,10) + '_' + e.배치파렛트번호 + '_' + e.배치박스번호
                    }
                    arr.push(obj);
                    this.ponums.push(e.배치발주번호);
                    this.pocenters.push(e.배치발주센터);
                }
            })
            this.lists = arr;


            this.podetail = [];
            this.total = {배치수량:0,확정수량:0,percentage:0};
            var tarr = [];
            stocks.forEach(e => {
                if(!tarr.includes(e.배치발주번호)){
                    tarr.push(e.배치발주번호)
                }
            })
            console.log(tarr)
            tarr.forEach(e => {
                this.podetail.push(this.$store.state.polist.find(ele => ele.발주번호 == e))
            })
            this.podetail.forEach(e => {
                e.확정수량 = 0
                e.배치수량 = 0
                
                e.발주세부내역.forEach(ele => {
                    ele.등록이미지 = this.$store.state.productdb.find(element => element.바코드 == ele.바코드).등록이미지;
                    e.확정수량 += parseInt(ele.확정수량)
                    ele.배치수량 = 0;
                    ele.미배치재고수량 = 0;
                    ele.구매완료수량 = 0;
                    ele.회송예정수량 = 0;
                    this.$store.state.stock.forEach(element => {
                        if(element.바코드 == ele.바코드){
                            if(element.stockinfo == "stock"){
                                ele.미배치재고수량++
                            }
                            if(element.stockinfo == "Pstock"){
                                ele.구매완료수량++
                            }
                            if(element.stockinfo == "Rstock"){
                                ele.회송예정수량++
                            }
                            if(element.배치발주번호 == e.발주번호 && element.stockinfo == "Astock"){
                                ele.배치수량++
                                e.배치수량++
                            }
                        }
                    })
                    if(ele.확정수량 == 0){
                        ele.percentage = 100
                    } else {
                        ele.percentage = parseInt(ele.배치수량/ele.확정수량*100)
                    }                    
                })
                if(e.확정수량 == 0){
                    e.percentage = 100
                } else {
                    e.percentage = parseInt(e.배치수량/e.확정수량*100)
                }
                this.total.확정수량 += e.확정수량;
                this.total.배치수량 += e.배치수량;
            })

            if(this.shipmentref.length == 0){
                this.shipmentref = this.podetail;
            } else {
                this.podetail.forEach(e => {
                    if(this.shipmentref.filter(ele => ele.발주번호 == e.발주번호).length == 0){
                        this.shipmentref.push(e)
                    }
                })
            }

            if(this.total.확정수량 == 0){
                this.total.percentage = 100
            } else {
                this.total.percentage = parseInt(this.total.배치수량/this.total.확정수량*100)
            }
        },
        importdatesinit(){
            var arr = this.$store.state.stock.filter(e => e.배치입고예정일.length > 0)
            var array = [];
            arr.forEach(e => {
                if(!array.includes(e.배치입고예정일)){
                    array.push(e.배치입고예정일)
                }
            })
            var obj = {};
            array.forEach(e => {
                obj = {
                    value: e,
                    text: e
                }
                if(!this.importdates.includes(obj)){
                    this.importdates.push(obj)
                }
            })

            this.importdates.sort(function(a,b){
                return new Date(b) - new Date(a);
            })
            this.importdate = this.importdates[0].text;
        },
        pluscolumn(){
            this.menus.push('')
        },
        minuscolumn(){
            this.menus.slice(0,this.selected.length-1)
        },
        additem(i){
            console.log(i)
        },
        submit() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitStatus = true
                window.scrollTo({ top: 0, behavior: 'smooth' });
            } else {    
                this.submitStatus = false
                this.$router.push({name: 'export.exportlist'})
            }
        },
        savesetting() {
            var payload = {
                companyid : this.$store.state.user.companyid,
                logis : this.menus
            };

            this.$store.commit('settingsupdate', payload)
            console.log(this.$store.state.settings)
            axios.post('/api/addpurchase/addsettings',payload)
            .then((res) => {alert(res.data.요청결과)})
        }
    }
}
</script>
<style>

  .my-class .dropdown-menu {
	
    max-height: 150px;
    overflow-y: auto;
  }
  .modal-dialog {
    max-width: 80%;
    position: absolute; 
    
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
</style>